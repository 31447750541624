import React from "react";
import { createGlobalStyle } from "styled-components";
import { withPrefix } from "gatsby";
import { Helmet } from "react-helmet";

import { graphql, useStaticQuery } from "gatsby";

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );
  return site.siteMetadata;
};

export const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url('https://use.typekit.net/nzd2qen.css');

body {
    background: black;
    color: white;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: 'Open Sans', sans-serif;
  }
  p, a {
    font-family: 'Open Sans';
    font-size: 14px;
  }
  h1, h2, h3, h4, h5, button {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  h3 { font-size: 16px; }
  a {
    color: lightblue;
  }
`;
export default ({
  children,
  metadata
}: {
  metadata?: { title; description };
  children: React.ReactNode;
}) => {
  const meta = { ...useSiteMetadata(), ...(metadata || {}) };
  return (
    <div>
      <GlobalStyle />
      <Helmet>
        <html lang="en" />
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        <meta
          name="google-site-verification"
          content="YM4QZTRhd9H2m6Zq8hvl6L-1SzJegXqhIZcpZCvwkdA"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon.png`}
          sizes="32x32"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={meta.title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix("/")}img/og-image.jpg`}
        />
      </Helmet>
      <div>{children}</div>
    </div>
  );
};
